.connectWalletToggle {
  background-color: rgba(0, 0, 0, 0.8);
}
.connectWalletToggle .modal-content {
  color: #ffffff;
  background-color: #111111;
  border: 1px solid #E6A900;
}
.connectWalletToggle .modal-content .modal-header {
  align-items: flex-start;
  border: none;
  padding: 20px;
}
.connectWalletToggle .modal-content .modal-body {
  padding: 10px;
}
.connectWalletToggle .modal-content button.btn-close {
  outline: none;
  box-shadow: none !important;
  opacity: 1;
  filter: invert(1);
}
.connectWalletToggle .modal-content .modal-title {
  font-size: 24px;
  margin-bottom: 0px;
  color: #ffffff;
  text-shadow: 0.3px 0.3px 0.3px #111;
}
.connectWalletToggle .modal-content .modal-title p {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  margin: 10px 0px 0px;
  text-shadow: none;
}
.connectWalletToggle .popupbtngrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.connectWalletToggle .popupbtngrp .btngrp {
  flex-basis: calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  margin: 0px 10px 20px;
}
.connectWalletToggle .popupbtngrp button,
.connectWalletToggle .popupbtngrp a {
  background-color: #111111;
  border: 1px solid #1a1a1a;
  color: #ffffff;
  font-size: 20px;
  padding: 20px 8px;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 1;
  display: block;
  text-decoration: none;
  box-shadow: inset 0px 0px 20px rgba(255, 255, 255, 0.12);
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.connectWalletToggle .popupbtngrp button img,
.connectWalletToggle .popupbtngrp a img {
  display: block;
  margin: 0 auto 15px;
  height: 45px;
  width: auto;
}
.connectWalletToggle .popupbtngrp button span,
.connectWalletToggle .popupbtngrp a span {
  display: block;
}/*# sourceMappingURL=ConnectWallet.css.map */