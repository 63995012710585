.levelDetailsBox .inrBox {
  background-repeat: no-repeat;
  overflow: visible;
  display: block;
  padding: 20px 20px 15px;
  text-align: left;
  border-radius: 0px 10px 0px 10px;
  height: 100%;
  position: relative;
  background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.18));
  border-top: 1px ridge #53555e;
  border-left: 1px groove #53555e;
  border-right: 1px ridge #53555e;
  border-bottom: 1px groove #53555e;
  box-shadow: inset 0px 0px 5px 3px rgba(1, 1, 1, 0.3);
}
.levelDetailsBox .inrBox::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 50px;
  height: 50px;
  border-top: 4px solid #E6A900;
  border-left: 4px solid #E6A900;
  z-index: 0;
}
.levelDetailsBox .inrBox::after {
  content: "";
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 50px;
  height: 50px;
  border-bottom: 4px solid #E6A900;
  border-right: 4px solid #E6A900;
  z-index: 0;
}
.levelDetailsBox .inrBox p {
  position: relative;
  z-index: 1;
  min-height: 40px;
  max-width: 155px;
  color: #ffffff;
  font-weight: 700;
}
.levelDetailsBox .inrBox h3 {
  font-size: 32px;
  margin-top: 25px;
  margin-bottom: 0px;
  color: #E6A900;
  font-weight: bold;
  position: relative;
  z-index: 1;
}
.levelDetailsBox .inrBox svg,
.levelDetailsBox .inrBox img {
  width: 70px !important;
  height: 70px !important;
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #726f00;
  z-index: 0;
}

.levelDetailsTable table tr {
  color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 700;
  border: 1px solid #626262;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
}
.levelDetailsTable table tr.headSec {
  background-color: rgb(56, 45, 12);
}
.levelDetailsTable table tr th {
  border: none;
  color: #ffffff !important;
  font-weight: 400;
  vertical-align: middle;
  --cui-table-accent-bg: transparent !important;
  word-wrap: break-word;
  line-height: 1;
}
.levelDetailsTable table tr th:nth-child(1) {
  flex: 0 0 20%;
  max-width: 20%;
}
.levelDetailsTable table tr th:nth-child(2) {
  flex: 0 0 25%;
  max-width: 25%;
}
.levelDetailsTable table tr th:nth-child(3) {
  flex: 0 0 15%;
  max-width: 15%;
}
.levelDetailsTable table tr th:nth-child(4) {
  flex: 0 0 20%;
  max-width: 20%;
}
.levelDetailsTable table tr th:nth-child(5) {
  flex: 0 0 20%;
  max-width: 20%;
  text-align: right;
}
.levelDetailsTable table tr td {
  border: none;
  color: #ffffff !important;
  font-weight: 400;
  vertical-align: middle;
  word-wrap: break-word;
  line-height: 1;
}
.levelDetailsTable table tr td:nth-child(1) {
  flex: 0 0 20%;
  max-width: 20%;
}
.levelDetailsTable table tr td:nth-child(2) {
  flex: 0 0 25%;
  max-width: 25%;
}
.levelDetailsTable table tr td:nth-child(3) {
  flex: 0 0 15%;
  max-width: 15%;
}
.levelDetailsTable table tr td:nth-child(4) {
  flex: 0 0 20%;
  max-width: 20%;
}
.levelDetailsTable table tr td:nth-child(5) {
  flex: 0 0 20%;
  max-width: 20%;
  text-align: right;
  justify-content: flex-end;
}
.levelDetailsTable table tr td a.idbox {
  display: inline-block;
  text-align: center;
  font-size: 18px;
  text-decoration: none;
  border: none;
  padding: 5px 10px 3px;
  line-height: 1;
  background-color: #ffffff;
  border-radius: 3px;
  color: #000000;
  font-weight: 600;
}
.levelDetailsTable table tr td.linkshr {
  display: flex;
  align-items: center;
}
.levelDetailsTable table tr td.linkshr a {
  width: 25px;
  height: auto;
  position: relative;
  top: -2px;
  margin-left: 10px;
}/*# sourceMappingURL=LevelsDetails.css.map */