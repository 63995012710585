.treeLevels .accordion-item {
  background-color: transparent;
  border: none;
  margin-bottom: 15px;
}
.treeLevels .treebox {
  padding: 0px;
  border-radius: 5px;
  text-align: left;
  background-color: transparent;
  border: none;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  color: #ffffff;
  height: 100%;
  cursor: pointer;
}
.treeLevels .treebox .accordion-button {
  padding: 20px;
  background-color: transparent;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}
.treeLevels .treebox .accordion-button::after {
  filter: invert(1);
}
.treeLevels .treebox .accordion-button .row {
  width: 100%;
}
.treeLevels .treebox .accordion-button .row .col-6:nth-child(2) {
  text-align: right;
  padding-right: 35px;
}
.treeLevels .accordion-body {
  border: 1px solid #626262;
  margin: -5px 0px 0px;
  border-radius: 0px 0px 5px 5px;
  border-top: none;
  padding: 25px 20px 5px;
}
.treeLevels .accordion-body .inrBoxList > .col-12 {
  margin-bottom: 15px;
}
.treeLevels .accordion-body .inrBoxList a {
  text-decoration: none;
}
.treeLevels .accordion-body .inrBoxList .row {
  margin: 0px 0px;
  background-color: rgba(230, 169, 0, 0.3098039216);
  padding: 10px;
  border: 1px solid #626262;
  color: #ffffff;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  padding-right: 35px;
  transition: all 0.1s ease-in-out;
}
.treeLevels .accordion-body .inrBoxList .row::after {
  content: ">";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.treeLevels .accordion-body .inrBoxList .row:hover {
  border-color: #E6A900;
}
.treeLevels .accordion-body .inrBoxList .row .col-6:nth-child(2) {
  text-align: right;
}/*# sourceMappingURL=Levels.css.map */