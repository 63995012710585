.registerMian {
  padding-top: 50px;
  padding-bottom: 30px;
}
.registerMian .uplineBox {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto 25px;
}
.registerMian .uplineBox label {
  display: block;
  flex: 0 0 120px;
  max-width: 120px;
  font-size: 18px;
  margin-right: 10px;
}
.registerMian .uplineBox input {
  display: block;
  width: 100%;
  flex: 0 0 calc(100% - 130px);
  max-width: calc(100% - 130px);
  font-size: 18px;
  line-height: 1;
  border: none;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px 15px;
  color: #000000;
}
.registerMian .col-6 {
  margin-bottom: 25px;
}
.registerMian .inrBox {
  overflow: visible;
  padding: 20px 20px 15px;
  text-align: left;
  border-radius: 0px 10px 0px 10px;
  height: 100%;
  position: relative;
  background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.18));
  border-top: 1px ridge #53555e;
  border-left: 1px groove #53555e;
  border-right: 1px ridge #53555e;
  border-bottom: 1px groove #53555e;
  box-shadow: inset 0px 0px 5px 3px rgba(1, 1, 1, 0.3);
}
.registerMian .inrBox::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 50px;
  height: 50px;
  border-top: 4px solid #E6A900;
  border-left: 4px solid #E6A900;
  z-index: 0;
}
.registerMian .inrBox::after {
  content: "";
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 50px;
  height: 50px;
  border-bottom: 4px solid #E6A900;
  border-right: 4px solid #E6A900;
  z-index: 0;
}
.registerMian .inrBox h3 {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0px;
  display: block;
}
.registerMian .inrBox i {
  font-size: 30px;
  display: inline-block;
  margin-bottom: 15px;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 1;
}
.registerMian .inrBox.done i {
  color: #aeffae;
}
.registerMian .inrBox.done h3 {
  color: #aeffae;
}
.registerMian .inrBox.error i {
  color: #ffbdb1;
}
.registerMian .inrBox.error h3 {
  color: #ffbdb1;
}
.registerMian .brn-grp {
  margin-top: 10px;
}
.registerMian .tablemainnet .col-md-6 {
  margin-bottom: 30px;
}
.registerMian .tablemainnet .mainnetInr {
  padding: 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
  height: 100%;
}
.registerMian .tablemainnet .mainnetInr h3 {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 15px;
}
.registerMian .tablemainnet .mainnetInr h3 span {
  color: #E6A900;
}
.registerMian .tablemainnet .mainnetInr .am_btn {
  margin-top: 20px;
}
.registerMian .tablemainnet .tableInr {
  padding: 30px 0px 30px 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
  height: 100%;
}
.registerMian .tablemainnet .tableInr h3 {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 15px;
}
.registerMian .tablemainnet .tableInr h3 span {
  color: #E6A900;
}
.registerMian .tablemainnet .tableInr .table-responsive {
  overflow-x: auto;
  max-height: 278px;
}
.registerMian .tablemainnet .tableInr table {
  margin-bottom: 0px;
}
.registerMian .tablemainnet .tableInr table tbody > tr:nth-of-type(odd) > * {
  background-color: #0c0c0c;
}
.registerMian .tablemainnet .tableInr table tr th {
  border-color: #0b0b0c;
  color: #E6A900;
  font-weight: 400;
  vertical-align: middle;
}
.registerMian .tablemainnet .tableInr table tr td {
  border-color: #0b0b0c;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
}
.registerMian .tablemainnet .tableInr table tr td img {
  height: 20px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
.registerMian .rdn {
  max-width: 410px;
  margin: 0 auto 50px;
}
.registerMian .rdn-control-arrow-icon {
  border-color: #E6A900 transparent transparent;
  top: 21px;
}
.registerMian .rdn-control-arrow-icon.is-open {
  border-color: transparent transparent #E6A900;
}
.registerMian .rdn-control {
  background-color: transparent;
  border: 1px solid #E6A900;
  color: #ffffff;
  border-radius: 5px;
  padding: 15px;
  text-align: left;
  line-height: 1;
  font-size: 18px;
  margin: 0 auto;
  text-transform: capitalize;
  cursor: pointer;
  width: 100%;
}
.registerMian .rdn-control.is-open {
  border-radius: 5px 5px 0px 0px;
}
.registerMian .rdn-control.is-open:hover {
  box-shadow: 0px 0px 0px 0px #E6A900;
}
.registerMian .rdn-control:hover {
  box-shadow: 0px 0px 20px -6px #E6A900;
}
.registerMian .rdn-drop,
.registerMian .rdn-selection {
  background-color: #000000;
  border: 1px solid #E6A900;
  box-shadow: 0px 10px 15px -15px #E6A900;
  border-radius: 0px 0px 5px 5px;
}
.registerMian .rdn-drop .rdn-drop-menu-option,
.registerMian .rdn-drop .rdn-drop-menu-group-option,
.registerMian .rdn-drop .rdn-selection-menu-option,
.registerMian .rdn-drop .rdn-selection-menu-group-option,
.registerMian .rdn-selection .rdn-drop-menu-option,
.registerMian .rdn-selection .rdn-drop-menu-group-option,
.registerMian .rdn-selection .rdn-selection-menu-option,
.registerMian .rdn-selection .rdn-selection-menu-group-option {
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  background-color: #181818;
  text-align: left;
  margin-bottom: 1px;
  padding: 10px 15px;
  font-size: 16px;
  text-transform: capitalize;
}
.registerMian .rdn-drop .rdn-drop-menu-option:nth-last-child(1),
.registerMian .rdn-drop .rdn-drop-menu-group-option:nth-last-child(1),
.registerMian .rdn-drop .rdn-selection-menu-option:nth-last-child(1),
.registerMian .rdn-drop .rdn-selection-menu-group-option:nth-last-child(1),
.registerMian .rdn-selection .rdn-drop-menu-option:nth-last-child(1),
.registerMian .rdn-selection .rdn-drop-menu-group-option:nth-last-child(1),
.registerMian .rdn-selection .rdn-selection-menu-option:nth-last-child(1),
.registerMian .rdn-selection .rdn-selection-menu-group-option:nth-last-child(1) {
  margin-bottom: 0px;
}
.registerMian .rdn-drop .rdn-drop-menu-option:hover,
.registerMian .rdn-drop .rdn-drop-menu-group-option:hover,
.registerMian .rdn-drop .rdn-selection-menu-option:hover,
.registerMian .rdn-drop .rdn-selection-menu-group-option:hover,
.registerMian .rdn-selection .rdn-drop-menu-option:hover,
.registerMian .rdn-selection .rdn-drop-menu-group-option:hover,
.registerMian .rdn-selection .rdn-selection-menu-option:hover,
.registerMian .rdn-selection .rdn-selection-menu-group-option:hover {
  background-color: rgb(56, 45, 12);
}

.tblMainnetMain {
  padding-top: 30px;
  padding-bottom: 30px;
}
.tblMainnetMain .tablemainnet .col-md-6 {
  margin-bottom: 30px;
}
.tblMainnetMain .tablemainnet .mainnetInr {
  padding: 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
  height: 100%;
  text-align: center;
  border: 1px solid #1c1c1c;
}
.tblMainnetMain .tablemainnet .mainnetInr h3 {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 15px;
}
.tblMainnetMain .tablemainnet .mainnetInr h3 span {
  color: #E6A900;
}
.tblMainnetMain .tablemainnet .mainnetInr .am_btn {
  margin-top: 20px;
}
.tblMainnetMain .tablemainnet .tableInr {
  padding: 30px 0px 30px 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  height: 100%;
}
.tblMainnetMain .tablemainnet .tableInr h3 {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 15px;
}
.tblMainnetMain .tablemainnet .tableInr h3 span {
  color: #E6A900;
}
.tblMainnetMain .tablemainnet .tableInr .table-responsive {
  overflow-x: auto;
  max-height: 274px;
}
.tblMainnetMain .tablemainnet .tableInr table {
  margin-bottom: 0px;
}
.tblMainnetMain .tablemainnet .tableInr table tbody > tr:nth-of-type(odd) > * {
  background-color: #262626;
}
.tblMainnetMain .tablemainnet .tableInr table tr th {
  border-color: #0b0b0c;
  color: #E6A900;
  font-weight: 400;
  vertical-align: middle;
}
.tblMainnetMain .tablemainnet .tableInr table tr td {
  border-color: #0b0b0c;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
}
.tblMainnetMain .tablemainnet .tableInr table tr td img {
  height: 20px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
}/*# sourceMappingURL=Register.css.map */