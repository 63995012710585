header {
  position: relative;
  top: 0px;
  transition: all 0.3s ease-in-out;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: transparent;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  -webkit-backdrop-filter: blur(60px);
          backdrop-filter: blur(60px);
}
header .rightboxmenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
header .rightboxmenu .profLogo {
  width: 50px;
  height: 50px;
  border: 1px solid #E6A900;
  display: inline-block;
  margin-left: 15px;
  padding: 5px;
  border-radius: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
header .rdn {
  max-width: 320px;
  margin: 0 0 0 auto;
}
header .rdn-control-arrow-icon {
  border-color: #E6A900 transparent transparent;
  top: 21px;
}
header .rdn-control-arrow-icon.is-open {
  border-color: transparent transparent #E6A900;
}
header .rdn-control {
  background-color: transparent;
  border: 1px solid #E6A900;
  color: #E6A900;
  border-radius: 5px;
  padding: 15px;
  text-align: left;
  line-height: 1;
  font-size: 18px;
  margin: 0 auto;
  text-transform: capitalize;
  cursor: pointer;
  padding-right: 30px;
}
header .rdn-control.is-open {
  border-radius: 5px 5px 0px 0px;
}
header .rdn-control.is-open:hover {
  box-shadow: 0px 0px 0px 0px #E6A900;
}
header .rdn-control:hover {
  box-shadow: 0px 0px 20px -6px #E6A900;
}
header .rdn-drop,
header .rdn-selection {
  background-color: #000000;
  border: 1px solid #E6A900;
  box-shadow: 0px 10px 15px -15px #E6A900;
  border-radius: 0px 0px 5px 5px;
}
header .rdn-drop .rdn-drop-menu-option,
header .rdn-drop .rdn-drop-menu-group-option,
header .rdn-drop .rdn-selection-menu-option,
header .rdn-drop .rdn-selection-menu-group-option,
header .rdn-selection .rdn-drop-menu-option,
header .rdn-selection .rdn-drop-menu-group-option,
header .rdn-selection .rdn-selection-menu-option,
header .rdn-selection .rdn-selection-menu-group-option {
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  background-color: #181818;
  text-align: left;
  margin-bottom: 1px;
  padding: 10px 15px;
  font-size: 16px;
  text-transform: capitalize;
}
header .rdn-drop .rdn-drop-menu-option:nth-last-child(1),
header .rdn-drop .rdn-drop-menu-group-option:nth-last-child(1),
header .rdn-drop .rdn-selection-menu-option:nth-last-child(1),
header .rdn-drop .rdn-selection-menu-group-option:nth-last-child(1),
header .rdn-selection .rdn-drop-menu-option:nth-last-child(1),
header .rdn-selection .rdn-drop-menu-group-option:nth-last-child(1),
header .rdn-selection .rdn-selection-menu-option:nth-last-child(1),
header .rdn-selection .rdn-selection-menu-group-option:nth-last-child(1) {
  margin-bottom: 0px;
}
header .rdn-drop .rdn-drop-menu-option:hover,
header .rdn-drop .rdn-drop-menu-group-option:hover,
header .rdn-drop .rdn-selection-menu-option:hover,
header .rdn-drop .rdn-selection-menu-group-option:hover,
header .rdn-selection .rdn-drop-menu-option:hover,
header .rdn-selection .rdn-drop-menu-group-option:hover,
header .rdn-selection .rdn-selection-menu-option:hover,
header .rdn-selection .rdn-selection-menu-group-option:hover {
  background-color: #775300;
}

.logo {
  display: inline-block;
  position: relative;
}
.logo img {
  width: 100%;
  max-width: 60px;
  padding: 0px 0px;
}/*# sourceMappingURL=header.css.map */