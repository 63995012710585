.informationPreviewMain {
  padding-top: 0px;
  padding-bottom: 0px;
}
.informationPreviewMain .tablemainnet .col-md-6 {
  margin-bottom: 30px;
}
.informationPreviewMain .tablemainnet .mainnetInr {
  padding: 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  height: 100%;
  border: 1px solid #1c1c1c;
  position: relative;
}
.informationPreviewMain .tablemainnet .mainnetInr::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  border-top: 4px solid #E6A900;
  border-left: 4px solid #E6A900;
  z-index: 0;
}
.informationPreviewMain .tablemainnet .mainnetInr::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  border-bottom: 4px solid #E6A900;
  border-right: 4px solid #E6A900;
  z-index: 0;
}
.informationPreviewMain .tablemainnet .mainnetInr h3 {
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 15px;
}
.informationPreviewMain .tablemainnet .mainnetInr p {
  display: block;
  color: #b7b7b7;
  margin: 0 0 5px;
}/*# sourceMappingURL=Faq.css.map */