.treeAeromatix .treebox {
  padding: 20px;
  border-radius: 5px;
  text-align: left;
  background-color: transparent;
  border: none;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  color: #ffffff;
  height: 100%;
  cursor: pointer;
}
.treeAeromatix .treebox .headeline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.treeAeromatix .treebox .headeline img {
  width: auto;
  height: 20px;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.treeAeromatix .treebox .downSticky {
  position: sticky;
  top: 100%;
}
.treeAeromatix .treebox .cartBox {
  text-align: center;
  margin: 30px auto 30px;
}
.treeAeromatix .treebox .cartBox button {
  border: 1px solid #ffffff;
  outline: none;
  background-color: #333333;
  color: #ffffff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  display: flex;
  margin-bottom: 10px;
  box-shadow: inset 0px 0px 10px rgb(0, 0, 0);
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.treeAeromatix .treebox .footerBox {
  justify-content: space-between;
  margin: 0px;
}
.treeAeromatix .treebox .footerlineone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px;
}
.treeAeromatix .treebox .footerlineone svg {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.treeAeromatix .treebox .footerline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px;
}
.treeAeromatix .treebox .footerline svg {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.treeAeromatix .treebox .itemsmain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.treeAeromatix .treebox .itemsmain .col6 {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.treeAeromatix .treebox .itemsmain .col12 {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.treeAeromatix .treebox .itemsmain span {
  display: block;
  border-radius: 0px;
  width: 30px;
  height: 30px;
  margin: 0 auto 0px;
  cursor: pointer;
}
.treeAeromatix .treebox .itemsmain span.dark {
  filter: brightness(0.3);
}
.treeAeromatix .treebox .itemsmain .col6 .col6 span {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
}
.treeAeromatix .treebox .itemsmain .col6 .col6 .col6 span {
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
}
.treeAeromatix .treebox .itemsmain .col6 .col6 .col6 .col6 span {
  width: 6px;
  height: 6px;
  margin-bottom: 0px;
}
.treeAeromatix .treebox .imgaeboxNft {
  text-align: center;
}
.treeAeromatix .treebox .imgaeboxNft h2 {
  font-size: 14px;
  margin: 0 0 20px;
  text-align: center;
}
.treeAeromatix .treebox .imgaeboxNft img {
  height: auto;
  width: 100%;
  border-radius: 3px;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
}
.treeAeromatix .treebox .imgaeboxNft .lazy-load-image-background {
  background-image: url("../../Assets/images/loading.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}
.treeAeromatix .treebox .imgaeboxNft .imgBxnft > .lazy-load-image-background {
  width: 100% !important;
  height: 100% !important;
}
.treeAeromatix .treebox span svg {
  width: 22px;
  height: auto;
}
.treeAeromatix .treebox .sbdb {
  display: block;
  font-size: 14px;
  opacity: 0.7;
  margin-top: 5px;
  line-height: 1;
}/*# sourceMappingURL=MyNfts.css.map */