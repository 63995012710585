@use '../../Assets/css/var';

.treeLevels {
    .accordion-item {
        background-color: transparent;
        border: none;
        margin-bottom: 15px;
    }

    .treebox {
        padding: 0px;
        border-radius: 5px;
        text-align: left;
        background-color: transparent;
        border: none;
        box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
        color: #ffffff;
        height: 100%;
        cursor: pointer;

        .accordion-button {
            padding: 20px;
            background-color: transparent;
            color: #ffffff;
            outline: none;
            box-shadow: none;

            &::after {
                filter: invert(1);
            }

            .row {
                width: 100%;

                .col-6 {
                    &:nth-child(2) {
                        text-align: right;
                        padding-right: 35px;
                    }
                }
            }
        }
    }

    .accordion-body {
        border: 1px solid #626262;
        margin: -5px 0px 0px;
        border-radius: 0px 0px 5px 5px;
        border-top: none;
        padding: 25px 20px 5px;

        .inrBoxList {
            &>.col-12 {
                margin-bottom: 15px;
            }

            a {
                text-decoration: none;
            }

            .row {
                margin: 0px 0px;
                background-color: #E6A9004f;
                padding: 10px;
                border: 1px solid #626262;
                color: #ffffff;
                height: 100%;
                cursor: pointer;
                border-radius: 5px;
                position: relative;
                padding-right: 35px;
                transition: all 0.1s ease-in-out;

                &::after {
                    content: ">";
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                }

                &:hover {
                    border-color: #E6A900;
                }

                .col-6 {
                    &:nth-child(2) {
                        text-align: right;
                    }
                }
            }
        }

    }
}