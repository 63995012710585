@use '../../Assets/css/var';

.loginMian {
    padding-top: 50px;
    padding-bottom: 30px;

    .uplineBox {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        margin: 0 auto 25px;

        label {
            display: block;
            flex: 0 0 100px;
            max-width: 100px;
            font-size: 18px;
            margin-right: 10px;
        }

        input {
            display: block;
            width: 100%;
            flex: 0 0 calc(100% - 110px);
            max-width: calc(100% - 110px);
            font-size: 18px;
            line-height: 1;
            border: none;
            background-color: #111111;
            border-radius: 5px;
            padding: 10px 15px;
            color: #ffffff;
        }
    }

    .col-6 {
        margin-bottom: 25px;
    }

    .inrBox {
        background-repeat: no-repeat;
        overflow: visible;
        display: block;
        padding: 20px 20px 15px;
        text-align: left;
        border-radius: 0px 10px 0px 10px;
        height: 100%;
        position: relative;
        background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.20));
        border-top: 1px ridge #53555e;
        border-left: 1px groove #53555e;
        border-right: 1px ridge #53555e;
        border-bottom: 1px groove #53555e;
        box-shadow: inset 0px 0px 5px 3px rgba(1, 1, 1, 0.3);

        &::before {
            content: "";
            position: absolute;
            top: -3px;
            left: -3px;
            width: 50px;
            height: 50px;
            border-top: 4px solid var.$colour-theme;
            border-left: 4px solid var.$colour-theme;
            z-index: 0;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: -3px;
            right: -3px;
            width: 50px;
            height: 50px;
            border-bottom: 4px solid var.$colour-theme;
            border-right: 4px solid var.$colour-theme;
            z-index: 0;
        }

        h3 {
            font-size: 32px;
            margin-top: 0px;
            margin-bottom: 10px;
            color: #ffffff;
            font-weight: bold;
            position: relative;
            z-index: 1;
        }

        p {
            position: relative;
            z-index: 1;
            min-height: 40px;
            max-width: 155px;
            color: #ffffff;
            font-weight: 400;
            opacity: 0.8;
            margin-bottom: 0px;
        }

        svg,
        img {
            width: auto;
            height: 65px;
            position: relative;
            bottom: 0px;
            right: 0px;
            color: #726f00;
            z-index: 0;
            display: block;
            margin-bottom: 20px;
        }
    }

    .brn-grp {
        margin-top: 10px;

        .am_btn {
            padding: 15px 30px;
        }
    }

    .tablemainnet {

        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 40px rgb(92 92 92 / 25%);
            height: 100%;

            .am_btn {
                margin-top: 20px;
            }
        }

        .tableInr {
            padding: 30px 0px 30px 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
            height: 100%;

            h3 {
                font-weight: 400;
                font-size: 28px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .table-responsive {
                overflow-x: auto;
                max-height: 278px;
            }

            table {
                margin-bottom: 0px;

                tbody>tr:nth-of-type(odd)>* {
                    background-color: #0c0c0c;
                }

                tr {

                    th {
                        border-color: #0b0b0c;
                        color: var.$colour-theme;
                        font-weight: 400;
                        vertical-align: middle;
                    }

                    td {
                        border-color: #0b0b0c;
                        color: #ffffff;
                        font-weight: 400;
                        vertical-align: middle;

                        img {
                            height: 20px;
                            width: auto;
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .rdn {
        max-width: 410px;
        margin: 0 auto 50px;
    }

    .rdn-control-arrow-icon {
        border-color: var.$colour-theme transparent transparent;
        top: 21px;
    }

    .rdn-control-arrow-icon.is-open {
        border-color: transparent transparent var.$colour-theme;
    }

    .rdn-control {
        background-color: transparent;
        border: 1px solid var.$colour-theme;
        color: var.$colour-white;
        border-radius: 5px;
        padding: 15px;
        text-align: left;
        line-height: 1;
        font-size: 18px;
        margin: 0 auto;
        text-transform: capitalize;
        cursor: pointer;

        &.is-open {
            border-radius: 5px 5px 0px 0px;

            &:hover {
                box-shadow: 0px 0px 0px 0px var.$colour-theme;
            }
        }

        &:hover {
            box-shadow: 0px 0px 20px -6px var.$colour-theme;
        }
    }

    .rdn-drop,
    .rdn-selection {
        background-color: #000000;
        border: 1px solid var.$colour-theme;
        box-shadow: 0px 10px 15px -15px var.$colour-theme;
        border-radius: 0px 0px 5px 5px;

        .rdn-drop-menu-option,
        .rdn-drop-menu-group-option,
        .rdn-selection-menu-option,
        .rdn-selection-menu-group-option {
            box-sizing: border-box;
            color: var.$colour-white;
            ;
            cursor: pointer;
            display: block;
            padding: 8px 10px;
            background-color: #181818;
            text-align: left;
            margin-bottom: 1px;
            padding: 10px 15px;
            font-size: 16px;
            text-transform: capitalize;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }

            &:hover {
                background-color: var.$colour-theme-dark;
            }
        }
    }
}

.accountPreviewMain {
    padding-top: 0px;
    padding-bottom: 0px;

    .tablemainnet {
        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
            height: 100%;
            border: 1px solid #1c1c1c;

            &.setminheight {
                min-height: 290px;
            }

            h3 {
                font-weight: 400;
                font-size: 24px;
                margin-bottom: 15px;
            }

            .inputBox {
                justify-content: space-between;
                align-items: center;
                margin: 25px 0px 30px;

                input {
                    flex-basis: calc(100% - 100px);
                    max-width: calc(100% - 100px);
                    margin-right: 0px;
                    border: 1px solid #E6A900;
                    background-color: transparent;
                    padding: 13px 15px;
                    border-right: 0px;
                    outline: none;
                    box-shadow: none;
                    color: #ffffff;
                    font-size: 18px;
                    border-radius: 5px 0px 0px 5px;

                    &::placeholder {
                        color: #ffffff;
                    }
                }

                .am_btn {
                    flex: 0 0 100px;
                    max-width: 100px;
                    margin-bottom: 0px;
                    box-shadow: none;
                    background-color: #E6A900;
                    padding: 15px 10px 16px;
                    border: 1px solid #E6A900;
                    color: #000000;
                    min-width: 90px;
                    text-transform: uppercase;
                    font-weight: 700;
                    border-radius: 0px 5px 5px 0px;
                }
            }

            .linkBox {
                justify-content: space-between;
                align-items: center;
                margin: 20px 0px 0px;

                &:nth-child(1) {
                    margin-top: 0px;
                }

                strong {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc(100% - 150px);
                    max-width: calc(100% - 150px);
                    padding: 0px;
                    font-size: 16px;
                    border: none;
                    margin-right: 20px;
                    border-radius: 5px;
                    outline: none;
                    box-shadow: none;
                }

                span {
                    flex: 0 0 130px;
                    max-width: 130px;
                    margin-bottom: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 0px;


                    svg {
                        width: 28px;
                        height: auto;
                        margin-left: 10px;
                    }
                }
            }

            .linkBoxBtm {
                justify-content: space-between;
                align-items: center;
                margin: 25px 0px 0px;
                position: sticky;
                top: 100%;
                border-top: 1px solid #E6A900;
                padding-top: 10px;

                p {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc(100% - 120px);
                    max-width: calc(100% - 120px);
                    padding: 0px;
                    margin: 0px;
                    font-size: 16px;
                    border: none;
                    margin-right: 20px;
                    border-radius: 5px;
                    outline: none;
                    box-shadow: none;
                }

                span {
                    flex: 0 0 100px;
                    max-width: 100px;
                    margin-bottom: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 0px;
                    font-size: 24px;


                    svg {
                        width: 28px;
                        height: auto;
                        margin-left: 10px;
                    }
                }
            }

            .autoLogin {
                justify-content: space-between;
                align-items: center;
                margin: 25px 0px 0px;

                .am_btn {
                    margin-bottom: 0px;
                    box-shadow: none;
                    min-width: 115px;
                    font-size: 16px;
                }
            }
        }

        .dashpgMbr {
            .mainnetInr {
                padding: 15px;

                h3 {
                    font-size: 16px;
                    min-height: 38px;
                }
            }
        }

        &.rightBox {
            .mainnetInr {
                padding: 0px;
                border-radius: 5px;
                box-shadow: none;
                height: 100%;
                border: none;

                &.reglogBox {
                    padding: 30px;
                    border-radius: 5px;
                    box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
                    height: 100%;
                    border: 1px solid #1c1c1c;
                    margin-bottom: 30px;
                    min-height: auto;
                }

                &.loginwithv1v2Main {
                    min-height: auto;
                }
            }
        }

        .tableInr {
            padding: 0px;
            height: 100%;
            margin-bottom: 40px;
            margin-top: 20px;
            position: relative;

            h3 {
                font-weight: 400;
                font-size: 24px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .table-responsive {
                overflow-x: auto;
                max-height: 450px;
                padding-right: 10px;

                &.dashboardPg {
                    max-height: 380px;
                    font-size: 14px;

                    table {
                        tr {
                            td {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            table {
                margin-bottom: 0px;
                min-width: 510px;

                tbody>tr:nth-of-type(odd)>* {
                    background-color: transparent;
                }

                tr {
                    color: #ffffff;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px;
                    margin-bottom: 15px;
                    background-color: rgba(255, 255, 255, 0);
                    font-weight: 700;
                    border: 1px solid #626262;
                    box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);

                    th {
                        border-color: #0b0b0c;
                        color: var.$colour-theme;
                        font-weight: 400;
                        vertical-align: middle;
                        padding: 0px;
                    }

                    td {
                        border: none;
                        color: #ffffff;
                        font-weight: 400;
                        vertical-align: middle;

                        img {
                            height: 28px;
                            width: auto;
                            display: inline-block;
                            margin-right: 20px;
                        }

                        svg {
                            height: 24px;
                            width: auto;
                            margin-right: 10px;
                        }

                        a {
                            display: inline-block;
                            text-align: center;
                            font-size: 18px;
                            text-decoration: none;
                            border: none;
                            padding: 5px 10px 3px;
                            line-height: 1;
                            background-color: #ffffff;
                            border-radius: 3px;
                            color: #000000;
                            font-weight: 600;
                        }

                        &.linkshr.text-end {
                            padding-right: 20px;

                            a {
                                background-color: transparent;
                            }
                        }

                        &.txtinf {
                            p {
                                margin: 0px;
                                display: inline-block;
                                color: var.$colour-theme;
                            }

                            span {
                                display: inline-block;
                                margin: 0 10px;
                                color: #9d9d9d;
                            }
                        }
                    }

                    &:nth-last-child(1) {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

.accountPreviewbtm {
    margin-bottom: 70px;
}