.partnersPreviewMain {
  padding-top: 0px;
  padding-bottom: 0px;
}
.partnersPreviewMain .tablemainnet .tableInr .table-responsive {
  overflow-x: auto;
}
.partnersPreviewMain .tablemainnet .tableInr table {
  margin-bottom: 0px;
  min-width: 510px;
  border-collapse: separate;
  border-spacing: 0px 15px;
}
.partnersPreviewMain .tablemainnet .tableInr table tbody > tr:nth-of-type(odd) > * {
  background-color: transparent;
}
.partnersPreviewMain .tablemainnet .tableInr table tr {
  color: #ffffff;
  border-radius: 6px;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 700;
  border: 1px solid #626262;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
}
.partnersPreviewMain .tablemainnet .tableInr table tr th {
  border: none;
  color: #c99404;
  font-weight: 400;
  vertical-align: middle;
  padding: 15px;
  font-size: 16px;
  text-align: left;
}
.partnersPreviewMain .tablemainnet .tableInr table tr td {
  border: none;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  padding: 15px;
}
.partnersPreviewMain .tablemainnet .tableInr table tr td img {
  height: 20px;
  width: auto;
  display: inline-block;
  margin-left: 10px;
  filter: invert(1);
  cursor: pointer;
}
.partnersPreviewMain .tablemainnet .tableInr table tr td svg {
  height: 24px;
  width: auto;
  margin-right: 10px;
}
.partnersPreviewMain .tablemainnet .tableInr table tr td a {
  background-color: #E6A900;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  color: #000000;
  border-radius: 2px;
  padding: 3px 8px;
  line-height: 1;
}
.partnersPreviewMain .tablemainnet .tableInr table tr td.linkshr.text-end {
  padding-right: 20px;
}
.partnersPreviewMain .tablemainnet .tableInr table tr td.txtinf p {
  margin: 0px;
  display: inline-block;
  color: #E6A900;
}
.partnersPreviewMain .tablemainnet .tableInr table tr td.txtinf span {
  display: inline-block;
  margin: 0 10px;
  color: #9d9d9d;
}/*# sourceMappingURL=Partners.css.map */