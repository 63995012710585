@use '../../Assets/css/var';

.faqOuterSection {
    .accordion-item {
        background-color: transparent;
        border: none;
        margin-bottom: 15px;
        border-radius: 5px;

        &:nth-child(odd) {
            .treebox {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, .2);
                    transform: translateX(0) skewX(-12deg);
                }
            }
        }

        &:nth-child(even) {
            .treebox {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, .2);
                    transform: translateX(0) skewX(12deg);
                }
            }
        }
    }

    .treebox {
        padding: 0px;
        border-radius: 5px;
        text-align: left;
        background-color: transparent;
        border: none;
        color: #ffffff;
        height: 100%;
        cursor: pointer;
        position: relative;

        .accordion-button {
            padding: 20px;
            background-color: transparent;
            color: #ffffff;
            outline: none;
            box-shadow: none;

            &::after {
                filter: grayscale(1) invert(1);
            }
        }
    }

    .accordion-body {
        padding: 15px;

        p {
            margin: 0px 0px 10px;
            color: #c9c9c9;

            a {
                color: #E6A900;
            }
        }
    }
}