.regModalToggle {
  background-color: rgba(0, 0, 0, 0.8);
}
.regModalToggle .modal-content {
  color: #ffffff;
  background-color: #111111;
  border: 1px solid #E6A900;
}
.regModalToggle .modal-content .modal-header {
  align-items: flex-start;
  border: none;
  padding: 20px 20px 10px;
}
.regModalToggle .modal-content .modal-body {
  padding: 10px 20px 20px;
}
.regModalToggle .modal-content .modal-body .inrBodySroll {
  max-height: 60vh;
  overflow: auto;
}
.regModalToggle .modal-content .modal-body .inrBodySroll p {
  font-size: 14px;
  opacity: 0.8;
}
.regModalToggle .modal-content .modal-body .inrBodySroll h4 {
  display: block;
  font-size: 18px;
  margin: 0 0 10px;
}
.regModalToggle .modal-content .modal-body .inrBodySroll strong {
  display: block;
  font-size: 18px;
  margin: 0 0 10px;
}
.regModalToggle .modal-content .modal-body .form-check {
  padding: 0px;
  position: relative;
}
.regModalToggle .modal-content .modal-body .form-check .form-check-input {
  margin: 0;
  margin-right: 10px;
  font-size: 22px;
}
.regModalToggle .modal-content .modal-body .error-message {
  color: tomato;
  position: absolute;
  top: calc(100% + 2px);
  left: 0px;
  font-size: 14px;
}
.regModalToggle .modal-content button.btn-close {
  outline: none;
  box-shadow: none !important;
  opacity: 1;
  filter: invert(1);
}
.regModalToggle .modal-content .modal-title {
  font-size: 24px;
  margin-bottom: 0px;
  color: #ffffff;
  text-shadow: 0.3px 0.3px 0.3px #111;
}
.regModalToggle .modal-content .modal-title p {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  margin: 10px 0px 0px;
  text-shadow: none;
}
.regModalToggle .popupbtngrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.regModalToggle .popupbtngrp .btngrp {
  flex-basis: calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  margin: 0px 10px 20px;
}
.regModalToggle .popupbtngrp button,
.regModalToggle .popupbtngrp a {
  background-color: #111111;
  border: 1px solid #1a1a1a;
  color: #ffffff;
  font-size: 20px;
  padding: 20px 8px;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 1;
  display: block;
  text-decoration: none;
  box-shadow: inset 0px 0px 20px rgba(255, 255, 255, 0.12);
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.regModalToggle .popupbtngrp button img,
.regModalToggle .popupbtngrp a img {
  display: block;
  margin: 0 auto 15px;
  height: 45px;
  width: auto;
}
.regModalToggle .popupbtngrp button span,
.regModalToggle .popupbtngrp a span {
  display: block;
}/*# sourceMappingURL=RegModal.css.map */