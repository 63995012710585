.dashboardMian {
  padding-top: 30px;
  padding-bottom: 30px;
}
.dashboardMian .uplineBox {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto 25px;
}
.dashboardMian .uplineBox label {
  display: block;
  flex: 0 0 100px;
  max-width: 100px;
  font-size: 18px;
  margin-right: 10px;
}
.dashboardMian .uplineBox input {
  display: block;
  width: 100%;
  flex: 0 0 calc(100% - 110px);
  max-width: calc(100% - 110px);
  font-size: 18px;
  line-height: 1;
  border: none;
  background-color: #111111;
  border-radius: 5px;
  padding: 10px 15px;
  color: #ffffff;
}
.dashboardMian .col-6 {
  margin-bottom: 25px;
}
.dashboardMian .inrBox {
  background-repeat: no-repeat;
  overflow: visible;
  display: block;
  padding: 20px 20px 15px;
  text-align: left;
  border-radius: 0px 10px 0px 10px;
  height: 100%;
  position: relative;
  background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.2));
  border-top: 1px ridge #53555e;
  border-left: 1px groove #53555e;
  border-right: 1px ridge #53555e;
  border-bottom: 1px groove #53555e;
  box-shadow: inset 0px 0px 5px 3px rgba(1, 1, 1, 0.3);
}
.dashboardMian .inrBox::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 50px;
  height: 50px;
  border-top: 4px solid #E6A900;
  border-left: 4px solid #E6A900;
  z-index: 0;
}
.dashboardMian .inrBox::after {
  content: "";
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 50px;
  height: 50px;
  border-bottom: 4px solid #E6A900;
  border-right: 4px solid #E6A900;
  z-index: 0;
}
.dashboardMian .inrBox h3 {
  font-size: 32px;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: bold;
  position: relative;
  z-index: 1;
}
.dashboardMian .inrBox p {
  position: relative;
  z-index: 1;
  min-height: 40px;
  max-width: 200px;
  color: #ffffff;
  font-weight: 400;
  opacity: 0.8;
  margin-bottom: 0px;
}
.dashboardMian .inrBox svg,
.dashboardMian .inrBox img {
  width: auto;
  height: 65px;
  position: relative;
  bottom: 0px;
  right: 0px;
  color: #726f00;
  z-index: 0;
  display: block;
  margin-bottom: 20px;
}
.dashboardMian .brn-grp {
  margin-top: 10px;
}
.dashboardMian .brn-grp .am_btn {
  padding: 15px 30px;
}
.dashboardMian .tablemainnet .col-md-6 {
  margin-bottom: 30px;
}
.dashboardMian .tablemainnet .mainnetInr {
  padding: 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
  height: 100%;
}
.dashboardMian .tablemainnet .mainnetInr .am_btn {
  margin-top: 20px;
}
.dashboardMian .tablemainnet .tableInr {
  padding: 30px 0px 30px 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
  height: 100%;
}
.dashboardMian .tablemainnet .tableInr h3 {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 15px;
}
.dashboardMian .tablemainnet .tableInr h3 span {
  color: #E6A900;
}
.dashboardMian .tablemainnet .tableInr .table-responsive {
  overflow-x: auto;
  max-height: 278px;
}
.dashboardMian .tablemainnet .tableInr table {
  margin-bottom: 0px;
}
.dashboardMian .tablemainnet .tableInr table tbody > tr:nth-of-type(odd) > * {
  background-color: #0c0c0c;
}
.dashboardMian .tablemainnet .tableInr table tr th {
  border-color: #0b0b0c;
  color: #E6A900;
  font-weight: 400;
  vertical-align: middle;
}
.dashboardMian .tablemainnet .tableInr table tr td {
  border-color: #0b0b0c;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
}
.dashboardMian .tablemainnet .tableInr table tr td img {
  height: 20px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
.dashboardMian .rdn {
  max-width: 410px;
  margin: 0 auto 50px;
}
.dashboardMian .rdn-control-arrow-icon {
  border-color: #E6A900 transparent transparent;
  top: 21px;
}
.dashboardMian .rdn-control-arrow-icon.is-open {
  border-color: transparent transparent #E6A900;
}
.dashboardMian .rdn-control {
  background-color: transparent;
  border: 1px solid #E6A900;
  color: #ffffff;
  border-radius: 5px;
  padding: 15px;
  text-align: left;
  line-height: 1;
  font-size: 18px;
  margin: 0 auto;
  text-transform: capitalize;
  cursor: pointer;
}
.dashboardMian .rdn-control.is-open {
  border-radius: 5px 5px 0px 0px;
}
.dashboardMian .rdn-control.is-open:hover {
  box-shadow: 0px 0px 0px 0px #E6A900;
}
.dashboardMian .rdn-control:hover {
  box-shadow: 0px 0px 20px -6px #E6A900;
}
.dashboardMian .rdn-drop,
.dashboardMian .rdn-selection {
  background-color: #000000;
  border: 1px solid #E6A900;
  box-shadow: 0px 10px 15px -15px #E6A900;
  border-radius: 0px 0px 5px 5px;
}
.dashboardMian .rdn-drop .rdn-drop-menu-option,
.dashboardMian .rdn-drop .rdn-drop-menu-group-option,
.dashboardMian .rdn-drop .rdn-selection-menu-option,
.dashboardMian .rdn-drop .rdn-selection-menu-group-option,
.dashboardMian .rdn-selection .rdn-drop-menu-option,
.dashboardMian .rdn-selection .rdn-drop-menu-group-option,
.dashboardMian .rdn-selection .rdn-selection-menu-option,
.dashboardMian .rdn-selection .rdn-selection-menu-group-option {
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  background-color: #181818;
  text-align: left;
  margin-bottom: 1px;
  padding: 10px 15px;
  font-size: 16px;
  text-transform: capitalize;
}
.dashboardMian .rdn-drop .rdn-drop-menu-option:nth-last-child(1),
.dashboardMian .rdn-drop .rdn-drop-menu-group-option:nth-last-child(1),
.dashboardMian .rdn-drop .rdn-selection-menu-option:nth-last-child(1),
.dashboardMian .rdn-drop .rdn-selection-menu-group-option:nth-last-child(1),
.dashboardMian .rdn-selection .rdn-drop-menu-option:nth-last-child(1),
.dashboardMian .rdn-selection .rdn-drop-menu-group-option:nth-last-child(1),
.dashboardMian .rdn-selection .rdn-selection-menu-option:nth-last-child(1),
.dashboardMian .rdn-selection .rdn-selection-menu-group-option:nth-last-child(1) {
  margin-bottom: 0px;
}
.dashboardMian .rdn-drop .rdn-drop-menu-option:hover,
.dashboardMian .rdn-drop .rdn-drop-menu-group-option:hover,
.dashboardMian .rdn-drop .rdn-selection-menu-option:hover,
.dashboardMian .rdn-drop .rdn-selection-menu-group-option:hover,
.dashboardMian .rdn-selection .rdn-drop-menu-option:hover,
.dashboardMian .rdn-selection .rdn-drop-menu-group-option:hover,
.dashboardMian .rdn-selection .rdn-selection-menu-option:hover,
.dashboardMian .rdn-selection .rdn-selection-menu-group-option:hover {
  background-color: rgb(56, 45, 12);
}

.dashboardPreviewMain {
  padding-top: 30px;
  padding-bottom: 0px;
}
.dashboardPreviewMain .poolhead {
  margin-bottom: 40px;
}
.dashboardPreviewMain .tablemainnet .col-md-6 {
  margin-bottom: 30px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr {
  padding: 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  height: 100%;
  border: 1px solid #1c1c1c;
}
.dashboardPreviewMain .tablemainnet .mainnetInr h3 {
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 15px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr strong {
  display: block;
  font-weight: 400;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .referralBtn {
  display: block;
  background-color: transparent;
  border: 1px solid #ffffff;
  margin-top: 8px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 12px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr a {
  display: block;
  color: #E6A900;
  text-decoration: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .inputBox {
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .inputBox input {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 120px);
  max-width: calc(100% - 120px);
  padding: 10px 15px;
  font-size: 20px;
  border: none;
  margin-right: 20px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .inputBox .am_btn {
  flex: 0 0 100px;
  max-width: 100px;
  margin-bottom: 0px;
  min-width: auto;
  box-shadow: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBox {
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBox strong {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 150px);
  max-width: calc(100% - 150px);
  padding: 0px;
  font-size: 20px;
  border: none;
  margin-right: 20px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBox span {
  flex: 0 0 130px;
  max-width: 130px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBox span svg {
  width: 28px;
  height: auto;
  margin-left: 10px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm {
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 0px;
  position: sticky;
  top: 100%;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm p {
  margin: 0px 0px 10px;
  padding: 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1215686275);
  line-height: 1.2;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm p:nth-last-child(1) {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm span {
  padding: 0px;
  font-size: 24px;
  margin-left: 15px;
  text-align: right;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm span svg {
  width: 28px;
  height: auto;
  margin-left: 10px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .addressCopyBtn {
  display: flex;
  align-items: center;
  border: 1px solid #747474;
  background-color: transparent;
  color: #ffffff;
  margin-top: 5px;
  padding: 8px 10px;
  border-radius: 3px;
  font-size: 16px;
  text-align: left;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .addressCopyBtn img {
  filter: invert(1);
  width: 25px;
  height: auto;
  margin-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.361);
  padding-left: 6px;
  cursor: pointer;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .autoLogin {
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .autoLogin .am_btn {
  margin-bottom: 0px;
  margin-top: 0px;
  min-width: auto;
  box-shadow: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox {
  text-align: center;
  box-shadow: none;
  border: none;
  padding: 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .inputBox {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .inputBox h2 {
  text-align: center;
  flex-basis: calc(100% - 115px);
  max-width: calc(100% - 115px);
  font-size: 18px;
  font-weight: 400;
  border: 1px dashed #363636;
  padding: 13px 5px;
  line-height: 1;
  border-radius: 5px;
  margin: 0px 15px 0 0;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .imgBox {
  border: 1px solid #00a6e7;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  outline-offset: 2px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .prfllgo {
  display: block;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox h3 {
  margin: 20px auto;
  text-align: center;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .memberBox {
  position: relative;
  margin-bottom: 40px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .memberBox .innermbrbx {
  padding-right: 130px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .totalIncomeBoxs .inrBox {
  border-color: transparent !important;
  box-shadow: none;
  background: transparent;
  border: 1px solid #53555e !important;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .totalIncomeBoxs .inrBox p {
  min-height: auto;
  max-width: 100%;
  margin: 0px 0px 10px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .totalIncomeBoxs .inrBox h3 {
  margin: 0px;
}
.dashboardPreviewMain .tablemainnet .tableInr {
  padding: 30px 0px 30px 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  height: 100%;
}
.dashboardPreviewMain .tablemainnet .tableInr h3 {
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 15px;
}
.dashboardPreviewMain .tablemainnet .tableInr h3 span {
  color: #E6A900;
}
.dashboardPreviewMain .tablemainnet .tableInr .table-responsive {
  overflow-x: auto;
  max-height: 295px;
}
.dashboardPreviewMain .tablemainnet .tableInr table {
  margin-bottom: 0px;
  min-width: 650px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tbody > tr:nth-of-type(odd) > * {
  background-color: #262626;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr th {
  border-color: #0b0b0c;
  color: #E6A900;
  font-weight: 400;
  vertical-align: middle;
  padding: 0px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td {
  border-color: #0b0b0c;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td img {
  height: 20px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td svg {
  height: 24px;
  width: auto;
  margin-right: 10px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td a {
  background-color: #E6A900;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  color: #000000;
  border-radius: 2px;
  padding: 3px 8px;
  line-height: 1;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.linkshr.text-end {
  padding-right: 20px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.linkshr.text-end a {
  background-color: transparent;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.txtinf p {
  margin: 0px;
  display: inline-block;
  color: #E6A900;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.txtinf span {
  display: inline-block;
  margin: 0 10px;
  color: #9d9d9d;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.rmbg a {
  background-color: transparent;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.txtinf > span {
  margin-left: 0px;
}
.dashboardPreviewMain.dashUserTbl .tableInr {
  height: auto;
  padding: 0px !important;
  margin: 0px;
  box-shadow: none;
}
.dashboardPreviewMain.dashUserTbl .tableInr .table-responsive {
  max-height: 400px;
}
.dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr {
  color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 700;
  border: 1px solid #626262;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
}
.dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr.headSec {
  background-color: rgb(56, 45, 12);
}
.dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr th {
  border: none;
  color: #ffffff !important;
  font-weight: 400;
  vertical-align: middle;
  --cui-table-accent-bg: transparent !important;
  word-wrap: break-word;
  line-height: 1;
}
.dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr td {
  border: none;
  color: #ffffff !important;
  font-weight: 400;
  vertical-align: middle;
  word-wrap: break-word;
  line-height: 1;
  background-color: transparent;
}

.treeAeromatix .treeboxDetail {
  padding: 20px;
  border-radius: 5px;
  text-align: left;
  background-color: transparent;
  border: none;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  color: #ffffff;
  height: 100%;
}
.treeAeromatix .treeboxDetail .headeline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.treeAeromatix .treeboxDetail .headeline img {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.treeAeromatix .treeboxDetail .downSticky {
  position: sticky;
  top: 100%;
}
.treeAeromatix .treeboxDetail .cartBox {
  text-align: center;
  margin: 30px auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.treeAeromatix .treeboxDetail .cartBox button {
  border: 1px solid #333333;
  outline: none;
  background-color: #333333;
  color: #ffffff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  display: flex;
  margin-bottom: 10px;
  box-shadow: inset 0px 0px 10px rgb(0, 0, 0);
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.treeAeromatix .treeboxDetail .cartBox > a {
  display: block;
  padding: 5px;
  line-height: 1.1;
}
.treeAeromatix .treeboxDetail .footerBox {
  justify-content: space-between;
  margin: 0px;
}
.treeAeromatix .treeboxDetail .footerlineone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px;
}
.treeAeromatix .treeboxDetail .footerlineone svg {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.treeAeromatix .treeboxDetail .footerline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px;
}
.treeAeromatix .treeboxDetail .footerline svg {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.treeAeromatix .treeboxDetail .itemsmain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.treeAeromatix .treeboxDetail .itemsmain .col6 {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.treeAeromatix .treeboxDetail .itemsmain .col12 {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.treeAeromatix .treeboxDetail .itemsmain span {
  display: block;
  border-radius: 0px;
  width: 40px;
  height: 40px;
  margin: 0 auto 0px;
  background-color: #E6A900;
  cursor: pointer;
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}
.treeAeromatix .treeboxDetail .itemsmain span.dark {
  filter: brightness(0.3);
}
.treeAeromatix .treeboxDetail .itemsmain span.whiteColor {
  background-color: #ffffff;
}
.treeAeromatix .treeboxDetail .itemsmain span.blueColor {
  background-color: #017dbd;
}
.treeAeromatix .treeboxDetail .itemsmain span.yellowColor {
  background-color: #d6d603;
}
.treeAeromatix .treeboxDetail .itemsmain span.greenColor {
  background-color: #00af00;
}
.treeAeromatix .treeboxDetail .itemsmain .col6 .col6 span {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}
.treeAeromatix .treeboxDetail .itemsmain .col6 .col6 .col6 span {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
}
.treeAeromatix .treeboxDetail .itemsmain .col6 .col6 .col6 .col6 span {
  width: 10px;
  height: 10px;
  margin-bottom: 0px;
}
.treeAeromatix .treeboxDetail .imgaeboxNft {
  text-align: center;
}
.treeAeromatix .treeboxDetail .imgaeboxNft h2 {
  font-size: 14px;
  margin: 0 0 20px;
  text-align: center;
}
.treeAeromatix .treeboxDetail .imgaeboxNft img {
  width: auto;
  height: auto;
  max-height: 250px;
  border-radius: 3px;
}
.treeAeromatix .dropdownBxCycle {
  margin: 20px auto;
  max-width: 320px;
}
.treeAeromatix .dropdownBxCycle .dropdown-toggle {
  color: #ffffff;
  font-size: 20px;
  background-color: #000000;
}
.treeAeromatix .dropdownBxCycle .dropdown-toggle + .dropdown-menu {
  transition: none !important;
  inset: unset !important;
  top: calc(100% - 1px) !important;
  left: 10px !important;
  transform: none !important;
  width: calc(100% - 20px) !important;
}

.treeBoxSingleView .sidepnl {
  background-color: #E6A900;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  flex: 0 0 80px;
  max-width: 80px;
  padding: 8px;
  color: #000000;
}
.treeBoxSingleView .sidepnl.disableBx {
  opacity: 0.5;
  pointer-events: none;
}
.treeBoxSingleView .treeBoxSingleInr {
  flex: 0 0 calc(100% - 160px);
  max-width: calc(100% - 160px);
}
.treeBoxSingleView .treeboxDetail .topheadbx {
  margin-bottom: 40px !important;
  border-bottom: 1px solid #1e1e1e;
  padding-bottom: 20px;
}
.treeBoxSingleView .treeboxDetail .downSticky {
  margin-top: 40px !important;
  border-top: 1px solid #1e1e1e;
  padding-top: 20px;
}
.treeBoxSingleView .treeboxDetail .itemsmain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.treeBoxSingleView .treeboxDetail .itemsmain .col6 {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.treeBoxSingleView .treeboxDetail .itemsmain .col12 {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.treeBoxSingleView .treeboxDetail .itemsmain span {
  width: 100px;
  height: 100px;
  cursor: pointer;
  -webkit-clip-path: none;
          clip-path: none;
  color: #000000;
  font-weight: 500;
  font-size: 34px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.treeBoxSingleView .treeboxDetail .itemsmain span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  z-index: -1;
  transform: rotate(45deg);
}
.treeBoxSingleView .treeboxDetail .itemsmain span img {
  width: 100%;
  padding: 20px;
}
.treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 span {
  width: 85px;
  height: 85px;
  font-size: 30px;
  margin-bottom: 25px;
}
.treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 span {
  width: 65px;
  height: 65px;
  margin-bottom: 25px;
  font-size: 26px;
}
.treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 .col6 span {
  width: 32px;
  height: 32px;
  margin-bottom: 0px;
  font-size: 16px;
}
.treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 .col6 span img {
  padding: 5px;
}
.treeBoxSingleView .treeboxDetail .imgaeboxNft {
  text-align: center;
}
.treeBoxSingleView .treeboxDetail .imgaeboxNft h2 {
  font-size: 14px;
  margin: 0 0 20px;
  text-align: center;
}
.treeBoxSingleView .treeboxDetail .imgaeboxNft img {
  width: auto;
  height: auto;
  max-height: 250px;
  border-radius: 3px;
}

.treeColorGrp {
  margin: 50px 0px 20px;
}
.treeColorGrp ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.treeColorGrp ul li {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 15px;
}
.treeColorGrp ul li span {
  display: flex;
  align-items: center;
}
.treeColorGrp ul li span svg {
  height: 20px;
  width: auto;
  margin-right: 8px;
}
.treeColorGrp ul li span::before {
  content: "";
  border-radius: 0px;
  background-color: #ffffff;
  margin-right: 8px;
  display: block;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  max-width: 20px;
  transform: rotate(45deg);
}
.treeColorGrp ul li span.remDot::before {
  display: none;
}

.treeColorGrp ul li .whiteColor::before {
  background-color: #ffffff;
}
.treeColorGrp ul li .blueColor::before {
  background-color: #017dbd;
}
.treeColorGrp ul li .yellowColor::before {
  background-color: #d6d603;
}
.treeColorGrp ul li .greenColor::before {
  background-color: #00af00;
}

.treeAeromatix .treeboxDetail .itemsmain span.greyColor {
  background-color: #4b4b4b;
}

.dateFormSection {
  position: relative;
  z-index: 999;
  margin-bottom: 10px;
}
.dateFormSection p {
  margin-bottom: 5px;
  text-align: left;
  font-weight: 400;
}
.dateFormSection input {
  width: 100%;
  display: block;
  padding: 10px;
  line-height: 1;
  background-color: #ffffff;
  color: #000000;
  font-weight: 400;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  outline: none;
  box-shadow: none;
}
.dateFormSection .dateFormInr {
  background-color: #111111;
  padding: 20px 20px 30px;
  border: 1px solid #2c2c2c;
  margin: 0;
  border-radius: 5px;
  position: relative;
  padding-right: 120px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #626262;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
}
.dateFormSection .css-b62m3t-container {
  width: 100%;
  display: block;
  line-height: 1;
  background-color: #ffffff;
  color: #000000;
  font-weight: 400;
  border: none;
  border-radius: 4px;
  font-size: 18px;
}
.dateFormSection .css-13cymwt-control,
.dateFormSection .css-t3ipsp-control {
  padding-top: 3px;
  padding-bottom: 2px;
  outline: none;
  box-shadow: none;
  border: none;
}
.dateFormSection .inrBoxSubmitBtn {
  display: inline-block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 30px;
  bottom: 31px;
  width: 80px;
  text-align: center;
}
.dateFormSection .inrBoxSubmitBtn button {
  display: block;
  background-color: #E6A900;
  width: 100%;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  line-height: 1;
  border: none;
  padding: 12px 6px;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
}
.dateFormSection .inrBoxSubmitBtn button:hover {
  opacity: 0.8;
}

.profilebox {
  padding: 0px;
  border-radius: 0;
  text-align: center;
  background-color: transparent;
  border: none;
  margin-top: -50px;
}
.profilebox .imgboxprof {
  position: relative;
  max-width: 100%;
  width: 250px;
  height: 250px;
  padding: 0px;
  margin: 10px auto -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profilebox .imgboxprof::before {
  content: "";
  position: absolute;
  background-image: url("../../Assets/images/ring.webp");
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-size: 190px;
  background-repeat: no-repeat;
}
.profilebox img {
  max-width: 100%;
  width: 100px;
  height: auto;
  position: relative;
  left: 0px;
  top: 0px;
}
.profilebox h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #ffffff;
}
.profilebox h4 {
  font-size: 18px;
  margin-bottom: 15px;
  font-family: MontserratRegular;
  font-weight: 400;
  color: #ffffff;
}
.profilebox h2 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #E6A900;
}
.profilebox .editProfileBtn {
  display: inline-block;
  margin-bottom: 10px;
  color: #01c4ff;
  font-size: 16px;
  font-weight: 400;
}
.profilebox .profileLinks {
  padding: 0px;
  margin: 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}
.profilebox .profileLinks li {
  margin: 0px 6px 8px;
}
.profilebox .profileLinks li a {
  display: block;
  outline: none;
  border: none;
  box-shadow: none;
}
.profilebox .profileLinks li svg {
  filter: invert(1);
  width: auto !important;
  height: 22px !important;
  margin: 0px !important;
}
.profilebox p {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #53555e;
  padding: 10px 5px;
  max-width: 220px;
  margin: 0 auto;
  border-radius: 5px;
  letter-spacing: 2px;
}
.profilebox svg {
  width: 28px !important;
  height: 28px !important;
  margin-left: 15px;
  cursor: pointer;
  text-align: center;
  padding: 0px;
  color: #E6A900;
}/*# sourceMappingURL=Treeviewdetail.css.map */