footer {
  position: sticky;
  top: 100%;
  padding: 20px;
  background-color: #142118;
  border-top: 1px solid #ffffff;
  box-shadow: 0px 0px 8px -2px #000;
  margin-top: 70px;
}
footer p {
  margin: 0px;
  text-align: left;
}
footer .socialIcons ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  padding: 0px;
  margin: 0px;
}
footer .socialIcons ul li {
  margin: 0px 8px;
}
footer .socialIcons ul li a {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}
footer .socialIcons ul li svg {
  fill: #ffffff;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 8px;
}/*# sourceMappingURL=Footer.css.map */