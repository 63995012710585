.dashboardMian {
  padding-top: 30px;
  padding-bottom: 30px;
}
.dashboardMian .uplineBox {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto 25px;
}
.dashboardMian .uplineBox label {
  display: block;
  flex: 0 0 100px;
  max-width: 100px;
  font-size: 18px;
  margin-right: 10px;
}
.dashboardMian .uplineBox input {
  display: block;
  width: 100%;
  flex: 0 0 calc(100% - 110px);
  max-width: calc(100% - 110px);
  font-size: 18px;
  line-height: 1;
  border: none;
  background-color: #111111;
  border-radius: 5px;
  padding: 10px 15px;
  color: #ffffff;
}
.dashboardMian .col-6 {
  margin-bottom: 25px;
}
.dashboardMian .inrBox {
  background-repeat: no-repeat;
  padding: 40px 10px;
  height: 100%;
  background-color: #111111;
  position: relative;
  overflow: visible;
  border-radius: 0px 10px 0px 10px;
  box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
  text-align: center;
  border: 1px solid #1c1c1c;
}
.dashboardMian .inrBox h3 {
  font-size: 42px;
  margin-bottom: 15px;
}
.dashboardMian .inrBox p {
  margin: 0px;
}
.dashboardMian .brn-grp {
  margin-top: 10px;
}
.dashboardMian .brn-grp .am_btn {
  padding: 15px 30px;
}
.dashboardMian .tablemainnet .col-md-6 {
  margin-bottom: 30px;
}
.dashboardMian .tablemainnet .mainnetInr {
  padding: 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
  height: 100%;
}
.dashboardMian .tablemainnet .mainnetInr .am_btn {
  margin-top: 20px;
}
.dashboardMian .tablemainnet .tableInr {
  padding: 30px 0px 30px 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
  height: 100%;
}
.dashboardMian .tablemainnet .tableInr h3 {
  font-size: 28px;
  margin-bottom: 15px;
}
.dashboardMian .tablemainnet .tableInr h3 span {
  color: #E6A900;
}
.dashboardMian .tablemainnet .tableInr .table-responsive {
  overflow-x: auto;
  max-height: 278px;
}
.dashboardMian .tablemainnet .tableInr table {
  margin-bottom: 0px;
}
.dashboardMian .tablemainnet .tableInr table tbody > tr:nth-of-type(odd) > * {
  background-color: #0c0c0c;
}
.dashboardMian .tablemainnet .tableInr table tr th {
  border-color: #0b0b0c;
  color: #E6A900;
  font-weight: 400;
  vertical-align: middle;
}
.dashboardMian .tablemainnet .tableInr table tr td {
  border-color: #0b0b0c;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
}
.dashboardMian .tablemainnet .tableInr table tr td img {
  height: 20px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
.dashboardMian .rdn {
  max-width: 410px;
  margin: 0 auto 50px;
}
.dashboardMian .rdn-control-arrow-icon {
  border-color: #E6A900 transparent transparent;
  top: 21px;
}
.dashboardMian .rdn-control-arrow-icon.is-open {
  border-color: transparent transparent #E6A900;
}
.dashboardMian .rdn-control {
  background-color: transparent;
  border: 1px solid #E6A900;
  color: #ffffff;
  border-radius: 5px;
  padding: 15px;
  text-align: left;
  line-height: 1;
  font-size: 18px;
  margin: 0 auto;
  text-transform: capitalize;
  cursor: pointer;
}
.dashboardMian .rdn-control.is-open {
  border-radius: 5px 5px 0px 0px;
}
.dashboardMian .rdn-control.is-open:hover {
  box-shadow: 0px 0px 0px 0px #E6A900;
}
.dashboardMian .rdn-control:hover {
  box-shadow: 0px 0px 20px -6px #E6A900;
}
.dashboardMian .rdn-drop,
.dashboardMian .rdn-selection {
  background-color: #000000;
  border: 1px solid #E6A900;
  box-shadow: 0px 10px 15px -15px #E6A900;
  border-radius: 0px 0px 5px 5px;
}
.dashboardMian .rdn-drop .rdn-drop-menu-option,
.dashboardMian .rdn-drop .rdn-drop-menu-group-option,
.dashboardMian .rdn-drop .rdn-selection-menu-option,
.dashboardMian .rdn-drop .rdn-selection-menu-group-option,
.dashboardMian .rdn-selection .rdn-drop-menu-option,
.dashboardMian .rdn-selection .rdn-drop-menu-group-option,
.dashboardMian .rdn-selection .rdn-selection-menu-option,
.dashboardMian .rdn-selection .rdn-selection-menu-group-option {
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  background-color: #181818;
  text-align: left;
  margin-bottom: 1px;
  padding: 10px 15px;
  font-size: 16px;
  text-transform: capitalize;
}
.dashboardMian .rdn-drop .rdn-drop-menu-option:nth-last-child(1),
.dashboardMian .rdn-drop .rdn-drop-menu-group-option:nth-last-child(1),
.dashboardMian .rdn-drop .rdn-selection-menu-option:nth-last-child(1),
.dashboardMian .rdn-drop .rdn-selection-menu-group-option:nth-last-child(1),
.dashboardMian .rdn-selection .rdn-drop-menu-option:nth-last-child(1),
.dashboardMian .rdn-selection .rdn-drop-menu-group-option:nth-last-child(1),
.dashboardMian .rdn-selection .rdn-selection-menu-option:nth-last-child(1),
.dashboardMian .rdn-selection .rdn-selection-menu-group-option:nth-last-child(1) {
  margin-bottom: 0px;
}
.dashboardMian .rdn-drop .rdn-drop-menu-option:hover,
.dashboardMian .rdn-drop .rdn-drop-menu-group-option:hover,
.dashboardMian .rdn-drop .rdn-selection-menu-option:hover,
.dashboardMian .rdn-drop .rdn-selection-menu-group-option:hover,
.dashboardMian .rdn-selection .rdn-drop-menu-option:hover,
.dashboardMian .rdn-selection .rdn-drop-menu-group-option:hover,
.dashboardMian .rdn-selection .rdn-selection-menu-option:hover,
.dashboardMian .rdn-selection .rdn-selection-menu-group-option:hover {
  background-color: rgb(56, 45, 12);
}

.dashboardPreviewMain {
  padding-top: 30px;
  padding-bottom: 0px;
}
.dashboardPreviewMain .tablemainnet .col-md-6 {
  margin-bottom: 30px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr {
  padding: 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  height: 100%;
  border: 1px solid #1c1c1c;
}
.dashboardPreviewMain .tablemainnet .mainnetInr h3 {
  font-size: 24px;
  margin-bottom: 15px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr strong {
  display: block;
  font-weight: 400;
}
.dashboardPreviewMain .tablemainnet .mainnetInr a {
  display: block;
  color: #E6A900;
  text-decoration: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .inputBox {
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .inputBox input {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 120px);
  max-width: calc(100% - 120px);
  padding: 10px 15px;
  font-size: 20px;
  border: none;
  margin-right: 20px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .inputBox .am_btn {
  flex: 0 0 100px;
  max-width: 100px;
  margin-bottom: 0px;
  min-width: auto;
  box-shadow: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBox {
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBox strong {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 150px);
  max-width: calc(100% - 150px);
  padding: 0px;
  font-size: 20px;
  border: none;
  margin-right: 20px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBox span {
  flex: 0 0 130px;
  max-width: 130px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBox span svg {
  width: 28px;
  height: auto;
  margin-left: 10px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm {
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 0px;
  position: sticky;
  top: 100%;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm p {
  margin: 0px 0px 10px;
  padding: 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1215686275);
  line-height: 1.2;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm p:nth-last-child(1) {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm span {
  padding: 0px;
  font-size: 24px;
  margin-left: 15px;
  text-align: right;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm span svg {
  width: 28px;
  height: auto;
  margin-left: 10px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .autoLogin {
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr .autoLogin .am_btn {
  margin-bottom: 0px;
  min-width: auto;
  box-shadow: none;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox {
  text-align: center;
  box-shadow: none;
  border: none;
  padding: 0px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .inputBox {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .inputBox h2 {
  text-align: center;
  flex-basis: calc(100% - 115px);
  max-width: calc(100% - 115px);
  font-size: 18px;
  border: 1px dashed #363636;
  padding: 13px 5px;
  line-height: 1;
  border-radius: 5px;
  margin: 0px 15px 0 0;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .imgBox {
  border: 1px solid #00a6e7;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  outline-offset: 2px;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .prfllgo {
  display: block;
}
.dashboardPreviewMain .tablemainnet .mainnetInr.profileBox h3 {
  margin: 20px auto;
  text-align: center;
}
.dashboardPreviewMain .tablemainnet .tableInr {
  padding: 30px 0px 30px 30px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  height: 100%;
}
.dashboardPreviewMain .tablemainnet .tableInr h3 {
  font-size: 24px;
  margin-bottom: 15px;
}
.dashboardPreviewMain .tablemainnet .tableInr h3 span {
  color: #E6A900;
}
.dashboardPreviewMain .tablemainnet .tableInr .table-responsive {
  overflow-x: auto;
  max-height: 295px;
}
.dashboardPreviewMain .tablemainnet .tableInr table {
  margin-bottom: 0px;
  min-width: 510px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tbody > tr:nth-of-type(odd) > * {
  background-color: #262626;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr th {
  border-color: #0b0b0c;
  color: #E6A900;
  font-weight: 400;
  vertical-align: middle;
  padding: 0px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td {
  border-color: #0b0b0c;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td img {
  height: 20px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td svg {
  height: 24px;
  width: auto;
  margin-right: 10px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td a {
  background-color: #dddddd;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  color: #000000;
  border-radius: 2px;
  padding: 3px 8px;
  line-height: 1;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.linkshr.text-end {
  padding-right: 20px;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.txtinf p {
  margin: 0px;
  display: inline-block;
  color: #E6A900;
}
.dashboardPreviewMain .tablemainnet .tableInr table tr td.txtinf span {
  display: inline-block;
  margin: 0 10px;
  color: #9d9d9d;
}

.treeAeromatix .treebox {
  padding: 20px;
  border-radius: 0px 10px 0px 10px;
  text-align: left;
  background-color: transparent;
  border: none;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  color: #ffffff;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.treeAeromatix .treebox::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  border-top: 4px solid #E6A900;
  border-left: 4px solid #E6A900;
  z-index: 0;
}
.treeAeromatix .treebox::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 35px;
  height: 35px;
  border-bottom: 4px solid #E6A900;
  border-right: 4px solid #E6A900;
  z-index: 0;
}
.treeAeromatix .treebox .headeline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.treeAeromatix .treebox .headeline img {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.treeAeromatix .treebox .downSticky {
  position: sticky;
  top: 100%;
}
.treeAeromatix .treebox .cartBox {
  text-align: center;
  margin: 30px auto 30px;
}
.treeAeromatix .treebox .cartBox button {
  border: 1px solid #333333;
  outline: none;
  background-color: #333333;
  color: #ffffff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  display: flex;
  margin-bottom: 10px;
  box-shadow: inset 0px 0px 10px rgb(0, 0, 0);
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.treeAeromatix .treebox .footerBox {
  justify-content: space-between;
  margin: 0px;
}
.treeAeromatix .treebox .footerlineone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px;
}
.treeAeromatix .treebox .footerlineone svg {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.treeAeromatix .treebox .footerline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px;
}
.treeAeromatix .treebox .footerline svg {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.treeAeromatix .treebox .itemsmain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.treeAeromatix .treebox .itemsmain .col6 {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.treeAeromatix .treebox .itemsmain .col12 {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.treeAeromatix .treebox .itemsmain span {
  display: block;
  border-radius: 0px;
  width: 30px;
  height: 30px;
  margin: 0 auto 0px;
  background-image: url(../../Assets/images/iconlogo.webp);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.treeAeromatix .treebox .itemsmain span.dark {
  filter: brightness(0.3);
}
.treeAeromatix .treebox .itemsmain .col6 .col6 span {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
}
.treeAeromatix .treebox .itemsmain .col6 .col6 .col6 span {
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
}
.treeAeromatix .treebox .itemsmain .col6 .col6 .col6 .col6 span {
  width: 6px;
  height: 6px;
  margin-bottom: 0px;
}
.treeAeromatix .treebox .imgaeboxNft {
  text-align: center;
}
.treeAeromatix .treebox .imgaeboxNft h2 {
  font-size: 14px;
  margin: 0 0 20px;
  text-align: center;
}
.treeAeromatix .treebox .imgaeboxNft img {
  height: auto;
  width: 100%;
  border-radius: 3px;
}/*# sourceMappingURL=Dashboard.css.map */