@use '../../Assets/css/var';

.dashboardMian {
    padding-top: 30px;
    padding-bottom: 30px;

    .uplineBox {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        margin: 0 auto 25px;

        label {
            display: block;
            flex: 0 0 100px;
            max-width: 100px;
            font-size: 18px;
            margin-right: 10px;
        }

        input {
            display: block;
            width: 100%;
            flex: 0 0 calc(100% - 110px);
            max-width: calc(100% - 110px);
            font-size: 18px;
            line-height: 1;
            border: none;
            background-color: #111111;
            border-radius: 5px;
            padding: 10px 15px;
            color: #ffffff;
        }
    }

    .col-6 {
        margin-bottom: 25px;
    }

    .inrBox {
        background-repeat: no-repeat;
        padding: 40px 10px;
        height: 100%;
        background-color: #111111;
        position: relative;
        overflow: visible;
        border-radius: 0px 10px 0px 10px;
        box-shadow: inset 0px 0px 40px rgb(92 92 92 / 25%);
        text-align: center;
        border: 1px solid #1c1c1c;

        h3 {
            font-size: 42px;
            margin-bottom: 15px;
        }

        p {
            margin: 0px;
        }

    }

    .brn-grp {
        margin-top: 10px;

        .am_btn {
            padding: 15px 30px;
        }
    }

    .tablemainnet {

        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 40px rgb(92 92 92 / 25%);
            height: 100%;

            .am_btn {
                margin-top: 20px;
            }
        }

        .tableInr {
            padding: 30px 0px 30px 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
            height: 100%;

            h3 {
                font-size: 28px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .table-responsive {
                overflow-x: auto;
                max-height: 278px;
            }

            table {
                margin-bottom: 0px;

                tbody>tr:nth-of-type(odd)>* {
                    background-color: #0c0c0c;
                }

                tr {

                    th {
                        border-color: #0b0b0c;
                        color: var.$colour-theme;
                        font-weight: 400;
                        vertical-align: middle;
                    }

                    td {
                        border-color: #0b0b0c;
                        color: #ffffff;
                        font-weight: 400;
                        vertical-align: middle;

                        img {
                            height: 20px;
                            width: auto;
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .rdn {
        max-width: 410px;
        margin: 0 auto 50px;
    }

    .rdn-control-arrow-icon {
        border-color: var.$colour-theme transparent transparent;
        top: 21px;
    }

    .rdn-control-arrow-icon.is-open {
        border-color: transparent transparent var.$colour-theme;
    }

    .rdn-control {
        background-color: transparent;
        border: 1px solid var.$colour-theme;
        color: var.$colour-white;
        border-radius: 5px;
        padding: 15px;
        text-align: left;
        line-height: 1;
        font-size: 18px;
        margin: 0 auto;
        text-transform: capitalize;
        cursor: pointer;

        &.is-open {
            border-radius: 5px 5px 0px 0px;

            &:hover {
                box-shadow: 0px 0px 0px 0px var.$colour-theme;
            }
        }

        &:hover {
            box-shadow: 0px 0px 20px -6px var.$colour-theme;
        }
    }

    .rdn-drop,
    .rdn-selection {
        background-color: #000000;
        border: 1px solid var.$colour-theme;
        box-shadow: 0px 10px 15px -15px var.$colour-theme;
        border-radius: 0px 0px 5px 5px;

        .rdn-drop-menu-option,
        .rdn-drop-menu-group-option,
        .rdn-selection-menu-option,
        .rdn-selection-menu-group-option {
            box-sizing: border-box;
            color: var.$colour-white;
            ;
            cursor: pointer;
            display: block;
            padding: 8px 10px;
            background-color: #181818;
            text-align: left;
            margin-bottom: 1px;
            padding: 10px 15px;
            font-size: 16px;
            text-transform: capitalize;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }

            &:hover {
                background-color: var.$colour-theme-dark;
            }
        }
    }
}

.dashboardPreviewMain {
    padding-top: 30px;
    padding-bottom: 0px;

    .tablemainnet {

        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
            height: 100%;
            border: 1px solid #1c1c1c;

            h3 {
                font-size: 24px;
                margin-bottom: 15px;
            }

            strong {
                display: block;
                font-weight: 400;
            }

            a {
                display: block;
                color: var.$colour-theme;
                text-decoration: none;
            }

            .inputBox {
                justify-content: space-between;
                align-items: center;
                margin: 25px 0px 0px;

                input {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc(100% - 120px);
                    max-width: calc(100% - 120px);
                    padding: 10px 15px;
                    font-size: 20px;
                    border: none;
                    margin-right: 20px;
                    border-radius: 5px;
                    outline: none;
                    box-shadow: none;
                }

                .am_btn {
                    flex: 0 0 100px;
                    max-width: 100px;
                    margin-bottom: 0px;
                    min-width: auto;
                    box-shadow: none;
                }
            }

            .linkBox {
                justify-content: space-between;
                align-items: center;
                margin: 25px 0px 0px;

                strong {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc(100% - 150px);
                    max-width: calc(100% - 150px);
                    padding: 0px;
                    font-size: 20px;
                    border: none;
                    margin-right: 20px;
                    border-radius: 5px;
                    outline: none;
                    box-shadow: none;
                }

                span {
                    flex: 0 0 130px;
                    max-width: 130px;
                    margin-bottom: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 0px;


                    svg {
                        width: 28px;
                        height: auto;
                        margin-left: 10px;
                    }
                }
            }

            .linkBoxBtm {
                justify-content: space-between;
                align-items: center;
                margin: 25px 0px 0px;
                position: sticky;
                top: 100%;

                p {
                    margin: 0px 0px 10px;
                    padding: 0px 0px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #ffffff1f;
                    line-height: 1.2;

                    &:nth-last-child(1) {
                        margin-bottom: 0px;
                        padding-bottom: 0px;
                        border-bottom: none;
                    }
                }

                span {
                    padding: 0px;
                    font-size: 24px;
                    margin-left: 15px;
                    text-align: right;

                    svg {
                        width: 28px;
                        height: auto;
                        margin-left: 10px;
                    }
                }
            }

            .autoLogin {
                justify-content: space-between;
                align-items: center;
                margin: 25px 0px 0px;

                .am_btn {
                    margin-bottom: 0px;
                    min-width: auto;
                    box-shadow: none;
                }
            }

            &.profileBox {
                text-align: center;
                box-shadow: none;
                border: none;
                padding: 0px;

                .inputBox {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 320px;

                    h2 {
                        text-align: center;
                        flex-basis: calc(100% - 115px);
                        max-width: calc(100% - 115px);
                        font-size: 18px;
                        border: 1px dashed #363636;
                        padding: 13px 5px;
                        line-height: 1;
                        border-radius: 5px;
                        margin: 0px 15px 0 0;
                    }
                }

                .imgBox {
                    border: 1px solid #00a6e7;
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    outline-offset: 2px;
                }

                .prfllgo {
                    display: block;
                }

                h3 {
                    margin: 20px auto;
                    text-align: center;
                }
            }
        }

        .tableInr {
            padding: 30px 0px 30px 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
            height: 100%;

            h3 {
                font-size: 24px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .table-responsive {
                overflow-x: auto;
                max-height: 295px;
            }

            table {
                margin-bottom: 0px;
                min-width: 510px;

                tbody>tr:nth-of-type(odd)>* {
                    background-color: #262626;
                }

                tr {

                    th {
                        border-color: #0b0b0c;
                        color: var.$colour-theme;
                        font-weight: 400;
                        vertical-align: middle;
                        padding: 0px;
                    }

                    td {
                        border-color: #0b0b0c;
                        color: #ffffff;
                        font-weight: 400;
                        vertical-align: middle;

                        img {
                            height: 20px;
                            width: auto;
                            display: inline-block;
                            margin-right: 10px;
                        }

                        svg {
                            height: 24px;
                            width: auto;
                            margin-right: 10px;
                        }

                        a {
                            background-color: #dddddd;
                            display: inline-block;
                            text-align: center;
                            font-size: 18px;
                            text-decoration: none;
                            font-weight: 500;
                            color: #000000;
                            border-radius: 2px;
                            padding: 3px 8px;
                            line-height: 1;
                        }

                        &.linkshr.text-end {
                            padding-right: 20px;
                        }

                        &.txtinf {
                            p {
                                margin: 0px;
                                display: inline-block;
                                color: var.$colour-theme;
                            }

                            span {
                                display: inline-block;
                                margin: 0 10px;
                                color: #9d9d9d;
                            }
                        }
                    }
                }
            }
        }
    }
}

.treeAeromatix {
    .treebox {
        padding: 20px;
        border-radius:0px 10px 0px 10px;
        text-align: left;
        background-color: transparent;
        border: none;
        box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
        color: #ffffff;
        height: 100%;
        cursor: pointer;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 35px;
            height: 35px;
            border-top: 4px solid var.$colour-theme;
            border-left: 4px solid var.$colour-theme;
            z-index: 0;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 35px;
            height: 35px;
            border-bottom: 4px solid var.$colour-theme;
            border-right: 4px solid var.$colour-theme;
            z-index: 0;
        }

        .headeline {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .downSticky {
            position: sticky;
            top: 100%;
        }

        .cartBox {
            text-align: center;
            margin: 30px auto 30px;

            button {
                border: 1px solid #333333;
                outline: none;
                background-color: #333333;
                color: #ffffff;
                cursor: pointer;
                width: 50px;
                height: 50px;
                border-radius: 60px;
                display: flex;
                margin-bottom: 10px;
                box-shadow: inset 0px 0px 10px rgb(0, 0, 0);
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .footerBox {
            justify-content: space-between;
            margin: 0px;
        }

        .footerlineone {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0px;

            svg {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .footerline {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0px;

            svg {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .itemsmain {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;

            .col6 {
                flex: 0 0 50%;
                max-width: 50%;
                display: flex;
                flex-wrap: wrap;
            }

            .col12 {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            span {
                display: block;
                border-radius: 0px;
                width: 30px;
                height: 30px;
                margin: 0 auto 0px;
                // background-color: var.$colour-theme;
                background-image: url(../../Assets/images/iconlogo.webp);
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
                // transform: rotate(45deg);

                &.dark {
                    filter: brightness(0.3);
                }
            }

            .col6 {
                .col6 {
                    span {
                        width: 20px;
                        height: 20px;
                        margin-bottom: 10px;
                    }

                    .col6 {
                        span {
                            width: 10px;
                            height: 10px;
                            margin-bottom: 10px;
                        }

                        .col6 {
                            span {
                                width: 6px;
                                height: 6px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }

        .imgaeboxNft {
            text-align: center;

            h2 {
                font-size: 14px;
                margin: 0 0 20px;
                text-align: center;
            }

            img {
                height: auto;
                width: 100%;
                border-radius: 3px;
            }
        }
    }
}