.sf_banner .slide img {
  height: 50vw;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 350px;
  background-color: rgba(104, 86, 133, 0.17);
}
.sf_banner .sliderbanner {
  position: relative;
}
.sf_banner .sliderbanner svg:not(.paging-dot) {
  position: absolute;
  top: -60px;
  left: -60px;
  width: 150px;
  height: 150px;
  z-index: 1;
  transform: scale(0.45);
  transform-origin: left top;
  filter: brightness(1.5);
}
.sf_banner .paging-item button {
  padding: 6px;
  opacity: 1 !important;
}
.sf_banner .paging-item button svg {
  width: 10px;
  height: 10px;
  fill: #ffffff !important;
}
.sf_banner .paging-item.active button svg {
  width: 12px;
  height: 12px;
}
.sf_banner .slider-container {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.sf_banner .slider-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}
.sf_banner .slider-container .paging-dot {
  fill: #c99404;
}
.sf_banner .slider-container .slider-control-centerleft {
  display: none;
}
.sf_banner .slider-container .slider-control-centerright {
  display: none;
}
.sf_banner .contentinfolt {
  max-width: 560px;
}
.sf_banner .contentinfolt h1 {
  font-size: 68px;
  margin-bottom: 30px;
}
.sf_banner .contentinfolt p {
  font-size: 24px;
  color: #afafaf;
  margin-bottom: 30px;
}/*# sourceMappingURL=Banner.css.map */