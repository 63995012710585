.faqOuterSection .accordion-item {
  background-color: transparent;
  border: none;
  margin-bottom: 15px;
  border-radius: 5px;
}
.faqOuterSection .accordion-item:nth-child(odd) .treebox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  transform: translateX(0) skewX(-12deg);
}
.faqOuterSection .accordion-item:nth-child(even) .treebox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
  transform: translateX(0) skewX(12deg);
}
.faqOuterSection .treebox {
  padding: 0px;
  border-radius: 5px;
  text-align: left;
  background-color: transparent;
  border: none;
  color: #ffffff;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.faqOuterSection .treebox .accordion-button {
  padding: 20px;
  background-color: transparent;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}
.faqOuterSection .treebox .accordion-button::after {
  filter: grayscale(1) invert(1);
}
.faqOuterSection .accordion-body {
  padding: 15px;
}
.faqOuterSection .accordion-body p {
  margin: 0px 0px 10px;
  color: #c9c9c9;
}
.faqOuterSection .accordion-body p a {
  color: #E6A900;
}/*# sourceMappingURL=FaqOuter.css.map */